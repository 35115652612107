import { INTEGER_REGEX } from "features/Common/constants/regExpPatterns";
import { is_empty } from "utils/validations";
import { CHANGE_STATE_KEY, CHANGE_STATES } from "../constants/Form.constants";

/**
Deeply compares two objects for equality.
@param {object} obj1 - The first object to compare.
@param {object} obj2 - The second object to compare.
@param {boolean} serialize - serialize the objects into JSON strings and then compare those strings
to perform a deep comparison of their properties' values.
@returns {boolean} - Returns true if the objects are deeply equal, false otherwise.
*/
export const deepEqual = (obj1, obj2, serialize = false) => {
  // Check if both objects are null
  if (obj1 === null && obj2 === null) {
    return true;
  }

  // Check if either object is null
  if (obj1 === null || obj2 === null) {
    return false;
  }

  // Check if both objects are of type object
  if (typeof obj1 === "object" && typeof obj2 === "object") {
    // If serialization is requested, use JSON.stringify for comparison
    if (serialize) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if each property value is deeply equal
    for (let key of keys1) {
      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  // If not objects, compare using strict equality
  return obj1 === obj2;
};

/**
 * add changes states to new array with comparision to old array
 * @param oldArray: existing array of objects
 * @param newArray: new array of objects
 * @param uuid: uniquew identifier in each object
 * @returns updated newArray of objects with change_state
 */
export const addChangeStateToArray = ({
  oldArray,
  newArray,
  uuid = "uuid",
}) => {
  const newObejct = {};
  newArray.forEach((item) => {
    newObejct[item[uuid]] = item;
  });

  const oldObejct = {};
  oldArray.forEach((item) => {
    oldObejct[item[uuid]] = item;
  });
  for (let key in oldObejct) {
    if (deepEqual(oldObejct[key], newObejct[key])) {
      newObejct[key].change_state = CHANGE_STATES.unchanged;
    } else if (!is_empty(newObejct[key])) {
      newObejct[key].change_state = CHANGE_STATES.updated;
    } else if (!(key in newObejct)) {
      newObejct[key] = {
        ...oldObejct[key],
        change_state: CHANGE_STATES.deleted,
      };
    }
  }

  for (let key in newObejct) {
    if (!(CHANGE_STATE_KEY in newObejct[key])) {
      newObejct[key] = { ...newObejct[key], change_state: CHANGE_STATES.added };
    }
  }

  return Object.values(newObejct);
};

export const finalFormIntegerParser = (value) => {
  if (!value) return undefined;
  return +value.replace(INTEGER_REGEX, "");
};
