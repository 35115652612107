import {
  isLoomVideoUrl as _isLoomVideoUrl,
  getLoomVideoEmbedUrlFromShareUrl as _getLoomVideoEmbedUrlFromShareUrl,
  isVimeoVideoLink as _isVimeoVideoLink,
  // @ts-ignore
} from "@my-scoot/exly-react-component-lib/build/cjs/utils/common/url";
import type {
  isLoomVideoUrl as isLoomVideoUrlType,
  getLoomVideoEmbedUrlFromShareUrl as getLoomVideoEmbedUrlFromShareUrlType,
  isVimeoVideoLink as isVimeoVideoLinkType,
} from "@my-scoot/exly-react-component-lib/build/types/utils/common/url";
import { deleteUrlParams } from "utils/urlUtils";

export const isVimeoVideoLink: typeof isVimeoVideoLinkType = _isVimeoVideoLink;

export const isLoomVideoUrl: typeof isLoomVideoUrlType = _isLoomVideoUrl;

export const getLoomVideoEmbedUrlFromShareUrl: typeof getLoomVideoEmbedUrlFromShareUrlType =
  _getLoomVideoEmbedUrlFromShareUrl;

export const deleteUrlParamsForHashRoute = (
  url: string,
  params: string[]
): string => {
  const urlObj = new URL(url);

  // Extract hash and convert to regular query
  const [hashPath, hashQuery] = urlObj.hash.includes("?")
    ? urlObj.hash.split("?")
    : [urlObj.hash, ""];

  const tempUrl = `${urlObj.origin}${urlObj.pathname}?${hashQuery}`;

  // Call your existing function
  const updatedUrl = deleteUrlParams(tempUrl, params);

  // Extract cleaned query string
  const updatedQuery = updatedUrl.split("?")[1] || "";

  // Reassemble hash URL with cleaned query
  return `${urlObj.origin}${urlObj.pathname}${hashPath}${
    updatedQuery ? `?${updatedQuery}` : ""
  }`;
};
