import { useInfiniteQuery } from "@tanstack/react-query";

export const useCustomInfiniteQuery = ({
  queryKey,
  queryFn,
  getNextPageParam,
  enabled,
  cacheTime,
  select,
}) => {
  return useInfiniteQuery({
    queryKey,
    queryFn,
    getNextPageParam,
    enabled,
    cacheTime,
    select,
  });
};
