import { maxLength, minLength } from "common/validate";
import { composeValidators } from "utils/validations";
import {
  BUTTON_TEXT_MAX_LENGTH,
  BUTTON_TEXT_MIN_LENGTH,
} from "../constants/ButtonBaseField.constants";

export const validateButtonText = composeValidators(
  minLength(
    BUTTON_TEXT_MIN_LENGTH,
    `Text must be atleast ${BUTTON_TEXT_MIN_LENGTH} characters`
  ),
  maxLength(
    BUTTON_TEXT_MAX_LENGTH,
    `Text must be atmost ${BUTTON_TEXT_MAX_LENGTH} characters`
  )
);
