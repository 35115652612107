export const GSTIN_REGEX =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z]{1}[0-9A-Z]{1}$/;

export const LINK_HREF_IN_HTML =
  /href\s*=\s*['"][^'"]*['"]|href\s*=\s*[^>\s]+/gi;

export const SENDER_NAME_IN_EMAIL = /^([^<]+)\s?</;
export const WIDTH_ATTR_IN_HTML = /width\s*=\s*["']?\d+px?["']?/gi;
export const WIDTH_STYLE_IN_CSS = /width:\s*\d+px/gi;

export const INTEGER_REGEX = /\D/g;
