export const BUTTON_TYPES = {
  NONE: 0,
  CALL_TO_ACTION: 1,
};

export const BUTTON_TYPE_OPTIONS = [
  { value: BUTTON_TYPES.NONE, label: "None" },
  { value: BUTTON_TYPES.CALL_TO_ACTION, label: "Call-to-action" },
];

export const BUTTON_TARGET_TYPE = {
  URL: "URL",
  PHONE_NUMBER: "PHONE_NUMBER",
};

export const BUTTON_TARGET_OPTIONS = [
  {
    value: BUTTON_TARGET_TYPE.URL,
    label: "Visit website",
  },
  {
    value: BUTTON_TARGET_TYPE.PHONE_NUMBER,
    label: "Call Phone no.",
  },
];

export const BUTTON_TEXT_MIN_LENGTH = 3;
export const BUTTON_TEXT_MAX_LENGTH = 20;
