import { AUTH_STORAGE_CONSTANTS } from "authConstants";
import local_storage_keys from "constants/LocalStorage.constants";
import { DOMAIN_LINKING_LOCAL_STORAGE_KEYS } from "features/Integration/modules/DomainLinking/constants/DomainLinking.constants";

export const LOCAL_STORAGE_KEYS = {
  NEXT_STEPS: "next_steps",
  BD_LOGIN: AUTH_STORAGE_CONSTANTS.BD_LOGIN,
  IS_STAFF: AUTH_STORAGE_CONSTANTS.IS_STAFF,
  BD_EMAIL: AUTH_STORAGE_CONSTANTS.BD_EMAIL,
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  TOKEN_UUID: "token_uuid",
  ORG_UUID: "org_uuid",
  IS_FEATURE_ONBOARDED: local_storage_keys.IS_FEATURE_ONBOARDED,
  DEMO_LISTING_LINK: "demo_listing_link",
  HIDE_INBOX_LEARN_MORE: "hide_inbox_learn_more", // this key is used to store boolean for whether to hide learn more in inbox or not
  ...DOMAIN_LINKING_LOCAL_STORAGE_KEYS,
};
