import { ExlyInput } from "common/form";
import { FormLabel } from "common/form/FormLabel/FormLabel";
import { maxLength } from "common/validate";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import {
  TEMPLATE_FORM_KEYS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React from "react";
import { composeValidators } from "react-admin";
import { Field } from "react-final-form";
import {
  footerLength,
  footerToolTipTitle,
  footerValidationText,
} from "ui/pages/whatsappBroadcast/create/CustomTemplates/moduleConstants";
import WordLimitChecker from "ui/pages/whatsappBroadcast/create/CustomTemplates/WordLimitChecker";

export const TemplateFooterField = () => {
  const footer = useFormFieldValue(TEMPLATE_FORM_KEYS.footer);
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  return (
    <div>
      <FormLabel text="Footer (optional)" info={footerToolTipTitle} />
      <Field
        name={TEMPLATE_FORM_KEYS.footer}
        size="small"
        fullWidth
        component={ExlyInput}
        validate={composeValidators(
          maxLength(footerLength, footerValidationText)
        )}
        placeholder="Enter text..."
        endAdornment={
          <WordLimitChecker counter={footer ? footer.length : 0} limit={60} />
        }
        disabled={readOnly}
      />
    </div>
  );
};
