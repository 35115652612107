import Call from "@material-ui/icons/Call";
import Redirect from "@material-ui/icons/OpenInNew";
import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import WhatsAppMobilePlaceholder from "features/AutomatedCommunication/modules/EditAutomatedCommunication/modules/CommActions/modules/ChangeWhatsAppTemplate/modules/WhatsAppTemplatePreview/modules/WhatsAppMobilePlaceholder/WhatsAppMobilePlaceholder";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import { is_empty } from "features/Common/utils/common.utils";
import {
  HEADER_TYPES,
  TEMPLATE_FORM_FE_KEYS,
  TEMPLATE_FORM_KEYS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { BUTTON_TARGET_TYPE } from "features/CreateWhatsappPresetNotificationTemplate/modules/TemplateDetails/modules/MessageDetailsForm/modules/TemplateButtonField/modules/ButtonBaseField/constants/ButtonBaseField.constants";
import React from "react";
import { EmptyStateDoc } from "ui/pages/whatsappBroadcast/create/Preview/components/EmptyStateDoc";
import { EmptyStateImage } from "ui/pages/whatsappBroadcast/create/Preview/components/EmptyStateImage";
import { EmptyStateVid } from "ui/pages/whatsappBroadcast/create/Preview/components/EmptyStateVideo";
import { renderWhatsappFormatWithVariables } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import styles from "../../TemplatePreview.module.css";
const { getHtmlFromEditorState } = RichTextEditorUtils;

const renderMediaOptions = {
  [HEADER_TYPES.IMG]: <EmptyStateImage className={styles.media} />,
  [HEADER_TYPES.VIDEO]: <EmptyStateVid className={styles.media} />,
  [HEADER_TYPES.DOC]: <EmptyStateDoc className={styles.media} />,
};

export const TemplatePreviewContent = () => {
  const templateUuid = useFormFieldValue(
    TEMPLATE_FORM_FE_KEYS.selected_template_uuid
  );
  const templateType = useFormFieldValue(TEMPLATE_FORM_KEYS.template_type);
  const headerData = useFormFieldValue(TEMPLATE_FORM_KEYS.header_data);
  const description = useFormFieldValue(TEMPLATE_FORM_KEYS.description);
  const footer = useFormFieldValue(TEMPLATE_FORM_KEYS.footer);
  const ctaData = useFormFieldValue(TEMPLATE_FORM_KEYS.cta_data);

  const renderWhatsappFormat = renderWhatsappFormatWithVariables({});
  const renderText = renderWhatsappFormat(getHtmlFromEditorState(description));

  return (
    <WhatsAppMobilePlaceholder
      classes={{
        root: styles.placeholder_root,
        templateContent: styles.template_content,
      }}
    >
      {templateUuid && (
        <div className={styles.whatsappChatBody}>
          <div className={`${styles.whatsappChatBodyText}`}>
            {headerData?.type === HEADER_TYPES.TEXT ? (
              <div className={styles.headerText}>{headerData.text}</div>
            ) : (
              renderMediaOptions[headerData?.type]
            )}
            <div
              className={`${
                templateType ? "" : styles.whatsappChatBodyTextPreview
              }`}
              // The below will render the `dangerouslySetInnerHTML` prop only if `templateType` is truthy
              {...(templateType && {
                dangerouslySetInnerHTML: { __html: renderText },
              })}
            >
              {templateType
                ? null
                : "<WhatsApp Broadcast Preview will show here>"}
            </div>
            <div className={styles.footerText}>{footer}</div>
            <div className={styles.buttonsContainer}>
              {!is_empty(ctaData) &&
                ctaData.map((cta) => {
                  return (
                    !is_empty(cta.text) && (
                      <div key={cta.text} className={styles.buttonWrapper}>
                        {" "}
                        {cta.type === BUTTON_TARGET_TYPE.URL ? (
                          <Redirect fontSize="small" />
                        ) : (
                          <Call fontSize="small" />
                        )}
                        {cta.text}{" "}
                      </div>
                    )
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </WhatsAppMobilePlaceholder>
  );
};
