import { dataProvider } from "data";
import { COMMS_APIS } from "./Comms.constants";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";

export const getPersonalizeVariables = async (
  eventType: number,
  abortSignal?: AbortSignal
) => {
  const { data, status } = await dataProvider.custom_request(
    COMMS_APIS.get_variables(eventType),
    apiMethods.GET,
    undefined,
    undefined,
    abortSignal
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error fetching comms variables: ${data} ${status}`);

  return data;
};
