import React from "react";
import styles from "./MailLinkStyles.module.css";
import { EXLY_SUPPORT_EMAIL } from "features/Account/Account.constants";

export const MailLink = ({
  emailId = EXLY_SUPPORT_EMAIL,
  linkText = EXLY_SUPPORT_EMAIL,
}) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`mailto:${emailId}`}
      className={styles.link}
    >
      {linkText}
    </a>
  );
};
