import { ExlyPhoneInput } from "common/form";
import { FormLabel } from "common/form/FormLabel/FormLabel";
import { ASIA_KOLKATA_TIMEZONE } from "constants/timezones/AllTimezonesArray";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import {
  TEMPLATE_FORM_KEYS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React from "react";
import { Field } from "react-final-form";
import { checkRequiredPhoneNumberValid } from "utils/validations";

export const PhoneButtonField = ({ name }) => {
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  return (
    <>
      <FormLabel text="Phone no" required />
      <Field
        component={ExlyPhoneInput}
        country={ASIA_KOLKATA_TIMEZONE.country_code.toLowerCase()}
        name={name}
        placeholder="Enter phone/whatsApp number"
        copyNumbersOnly={false}
        inputProps={{ name }}
        validate={checkRequiredPhoneNumberValid}
        source={TEMPLATE_FORM_KEYS.phone_number}
        disabled={readOnly}
      />
    </>
  );
};
