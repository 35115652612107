import { useEffect, useState } from "react";
import { logError } from "utils/error";
import { getPersonalizeVariables } from "./Comms.ApiCalls";
import { IComms } from "./Comms.interfaces";

const useCommsVariables = ({ eventType }: { eventType: number }) => {
  const [variables, setVariables] = useState<IComms.IVariables>([]);

  useEffect(() => {
    const handleFetchVariables = () => {
      const abortController = new AbortController();

      const handleError = (error: Error) => {
        logError({
          error,
          when: "calling getPersonalizeVariables",
          severity: "blocker",
          occuredAt: "src/features/Common/modules/Comms/useCommsVariables.ts",
        });
      };

      const handleAbort = () => {
        abortController.abort();
      };

      getPersonalizeVariables(eventType, abortController.signal)
        .then(setVariables)
        .catch(handleError);

      return handleAbort;
    };

    return handleFetchVariables();
  }, []);

  return { variables };
};

export default useCommsVariables;
