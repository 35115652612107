import { schedule_types_ids } from "constants/schedule";

export const COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES = {
  BROADCAST: 1,
  DRIP: 2,
  APPOINTMENT: 3,
  GROUP_CLASS: 4,
  QPP: 5,
  RECORDED_CONTENT: 6,
  FLEXIBLE_CLASS: 7,
  LIVE_WEBINAR: 8,
  WHATSAPP_COMMUNITY: 9,
  BRANDED_COMMUNITY: 10,
  WORKSHOP: 11,
  WHATSAPP_CUSTOM_TEMPLATES: 12,
};

export const LISTING_SPECIFIC_PERSONALIZE_VARIABLES_ENUM = {
  [schedule_types_ids.one_on_one_appointment]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.APPOINTMENT,
  [schedule_types_ids.group_class]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.GROUP_CLASS,
  [schedule_types_ids.no_schedule]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.QPP,
  [schedule_types_ids.content]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.RECORDED_CONTENT,
  [schedule_types_ids.rolling_class]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.FLEXIBLE_CLASS,
  [schedule_types_ids.webinar]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.LIVE_WEBINAR,
  [schedule_types_ids.whatsapp]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.WHATSAPP_COMMUNITY,
  [schedule_types_ids.branded_community]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.BRANDED_COMMUNITY,
  [schedule_types_ids.workshop]:
    COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.WORKSHOP,
};

export const COMMS_APIS = {
  // eventType: The communication type for which personalization variables need to be fetched.
  // enum: COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES (src/features/Common/modules/Comms/Comms.constants.ts)
  get_variables: (eventType: number) =>
    `notifications/personalise/variables/get/${eventType}`,
};
