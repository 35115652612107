import { useEffect, useRef } from "react";

// A custom hook for implementing infinite scrolling using the IntersectionObserver API.
// It triggers the `onLoadMore` callback when the referenced element comes into view.
export const useInfiniteScroll = ({ loading, onLoadMore = () => {} } = {}) => {
  const lastElementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (loading) return;

        if (entry.isIntersecting) {
          onLoadMore();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      }
    );

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [onLoadMore, loading]);

  return { lastElementRef };
};
