// APIS
export const UPDATE_CREATOR_DATA_API = "users/v2/creator/update";
export const FETCH_CREATOR_CONFIG_API = "host/config/constants";

// AUTH DATA KEYS
export const AUTH_KEYS = {
  social_info: "social_info",
  lead_keys: "lead_keys",
};

// CREATOR CONFIG KEYS
export const CREATOR_CONFIG_KEYS = {
  disable_universal_link_payments: "disable_universal_link_payments",
  website_url: "website_url",
};

export const creator_miscellaneous_data_types = {
  AWARDS_CERTIFICATES: 1,
  BRAND_COLLABORATION: 2,
  MEDIA_MENTIONS: 3,
  OFFERING_SECTION: 4,
  INTRO_VIDEO: 5,
  ADDITIONAL_POINTERS: 6,
  BLOGS: 7,
  TEAMS: 8,
  CONTENT_CATALOGUE: 9,
  ABOUT_SECTION: 10,
  CUSTOM_FOOTER: 11,
};
