import React from "react";
import styles from "./TemplatePreview.module.css";
import { TemplatePreviewContent } from "./modules/TemplatePreviewContent/TemplatePreviewContent";

export const TemplatePreview = () => {
  return (
    <div className={styles.root}>
      <div className={styles.labels}>
        <div>Preview</div>
        <div>This is how your template will look</div>
      </div>

      <TemplatePreviewContent />
    </div>
  );
};
