import { ExlyDropdown, ExlyInput } from "common/form";
import { FormLabel } from "common/form/FormLabel/FormLabel";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import {
  HEADER_TYPE_OPTIONS,
  HEADER_TYPES,
  TEMPLATE_FORM_FE_KEYS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React from "react";
import { Field } from "react-final-form";
import { validateTemplateHeader } from "./utils/TemplateHeaderField.utils";
import styles from "./TemplateHeaderField.module.css";

export const TemplateHeaderField = () => {
  const headerType = useFormFieldValue(TEMPLATE_FORM_FE_KEYS.header_type);
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  return (
    <div>
      <FormLabel text="Header" required />

      <div className={styles.fields}>
        <div className={styles.dropdown}>
          <Field
            name={TEMPLATE_FORM_FE_KEYS.header_type}
            component={ExlyDropdown}
            options={HEADER_TYPE_OPTIONS}
            size="small"
            fullWidth
            disabled={readOnly}
          />
        </div>

        {headerType === HEADER_TYPES.TEXT && (
          <div className="flex-grow-1">
            <Field
              name={TEMPLATE_FORM_FE_KEYS.header_text}
              component={ExlyInput}
              size="small"
              placeholder="Enter text..."
              validate={validateTemplateHeader}
              fullWidth
              disabled={readOnly}
            />
          </div>
        )}
      </div>
    </div>
  );
};
