import { useTemplate } from "features/CreateWhatsappPresetNotificationTemplate/utils/useTemplate";
import React from "react";
import styles from "./TemplateSelection.module.css";
import { Header } from "./modules/Header/Header";
import { TemplatesList } from "./modules/TemplatesList/TemplatesList";

export const TemplateSelection = () => {
  useTemplate();

  return (
    <div className={styles.root}>
      <Header />

      <TemplatesList />
    </div>
  );
};
