import { IComms } from "./Comms.interfaces";

export const formatCommsVariableForRTE = (variables: IComms.IVariables) =>
  variables?.map(({ display_name, info_text, key }) => ({
    label: display_name,
    value: `{{${key}}}`,
    tooltip: info_text,
  }));

export const getPersonalizeVariablesLabelsMap = (
  variables: IComms.IVariables
) =>
  variables?.reduce((prev, { key, display_name }) => {
    prev[key] = display_name;
    return prev;
  }, {} as { [variableKey: string]: string });
