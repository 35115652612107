import React from "react";
import styles from "./Header.module.css";

export const Header = () => {
  return (
    <div className={styles.root}>
      <div>Choose Preset Notification</div>
      <div>
        Choose the preset notification you’re creating this template for
      </div>
    </div>
  );
};
