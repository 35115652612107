import {
  Button,
  RichTextEditorUtils,
} from "@my-scoot/component-library-legacy";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import ArrowOutward from "assets/vectors/arrow_outward.svg";
import Rule from "assets/vectors/rule.svg";
import ExlyImage from "common/Components/ExlyImage";
import { ExlyRichTextEditorModal } from "common/form";
import { FormLabel } from "common/form/FormLabel/FormLabel";
import { COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES } from "features/Common/modules/Comms/Comms.constants";
import { formatCommsVariableForRTE } from "features/Common/modules/Comms/Comms.utils";
import useCommsVariables from "features/Common/modules/Comms/useCommsVariables";
import {
  TEMPLATE_FORM_KEYS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import PersonalizeCta from "features/CustomerComms/modules/PersonalizeCta/PersonalizeCta";
import React, { useMemo, useRef, useState } from "react";
import { Field } from "react-final-form";
import { COMMS_FOCUSABLE_ELEMENTS } from "ui/pages/communications/CustomEmail/ComposeEmail/data";
import OfferingNotifier from "ui/pages/whatsappBroadcast/Components/OfferingNotifier";
import { email_variable_values } from "ui/pages/whatsappBroadcast/create/CustomTemplates/moduleConstants";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import styles from "./TemplateBodyField.module.css";
import { validatePollDescriptionLength } from "./utils/TemplateBodyField.validators";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
const { getHtmlFromEditorState } = RichTextEditorUtils;

export const TemplateBodyField = () => {
  const { notify } = useNotifications();
  const [lastFocusedElement, setLastFocusedElement] = useState(null);
  const [personalizeAnchorEl, setPersonalizeAnchorEl] = useState(null);
  const lastFocusedElementRef = useRef(lastFocusedElement);
  lastFocusedElementRef.current = lastFocusedElement;
  const { variables } = useCommsVariables({
    eventType:
      COMMS_EVENT_DRIVEN_PERSONALIZE_VARIABLES.WHATSAPP_CUSTOM_TEMPLATES,
  });
  const personaliseVariables = formatCommsVariableForRTE(variables);
  const isDesktop = useIsDesktop();
  const [
    isOfferingNotifyModalOpen,
    openOfferingNotifyModal,
    closeOfferingNotifyModal,
  ] = useToggleState(false);
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  const CustomFooter = useMemo(() => {
    const Footer = ({ onSave, variablesProps }) => {
      return (
        <div
          onClick={() => setLastFocusedElement(COMMS_FOCUSABLE_ELEMENTS.footer)}
          className={styles.entireFooterWrapper}
        >
          <div
            className={styles.footerRulesWrapper}
            onClick={() => {
              window.open(
                "https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/",
                "_blank"
              );
            }}
          >
            <ExlyImage src={Rule} alt={"Rule"} />
            Meta Template Guidelines
            <ExlyImage src={ArrowOutward} alt={"ArrowOutward"} />
          </div>
          <div className={styles.customFooter}>
            <PersonalizeCta
              onClick={handlePersonalize}
              active={!!variablesProps.anchorEl}
              disabled={readOnly}
            />
            <Button disabled={readOnly} onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      );
    };

    return Footer;
  }, []);

  const handleRTEFocus = (e) => {
    e.stopPropagation();
    setLastFocusedElement(COMMS_FOCUSABLE_ELEMENTS.rte);
  };

  const handlePersonalize = (e) => {
    e.stopPropagation();

    if (lastFocusedElementRef.current === COMMS_FOCUSABLE_ELEMENTS.rte) {
      setPersonalizeAnchorEl(e.currentTarget);
    } else {
      notify(
        "Select the body to insert variables",
        notification_color_keys.info
      );
    }
  };

  const handleClosePersonalize = () => {
    setPersonalizeAnchorEl(null);
  };

  const handleSaveBody = ({ onEditorStateChange, setOpen, state }) => {
    onEditorStateChange(state);
    setOpen(false);

    const htmlValue = getHtmlFromEditorState(state);
    if (htmlValue?.indexOf(email_variable_values.offering_location) > -1)
      openOfferingNotifyModal();
  };

  return (
    <div>
      <FormLabel text="Body" required />

      <Field
        name={TEMPLATE_FORM_KEYS.description}
        component={ExlyRichTextEditorModal}
        toolbarPosition="top"
        modalTitle={"Body"}
        customWrapperClassName={styles.customWrapperRTE}
        primaryBtnText="Save"
        secondaryBtnText="Cancel"
        placeholder={""}
        CustomFooter={CustomFooter}
        showToolbar={false}
        showSaveButton={false}
        showChipsOnReadOnly={true}
        removeStylesOnPaste={true}
        showRte={true}
        autoFocus={true}
        editableProps={{
          onFocus: handleRTEFocus,
        }}
        onSave={handleSaveBody}
        validate={validatePollDescriptionLength}
        readOnly={readOnly}
        variablesProps={{
          anchorEl: personalizeAnchorEl,
          chips: personaliseVariables,
          onClose: handleClosePersonalize,
          classes: { paper: styles.paper },
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          ModalProps: {
            disablePortal: false,
          },
          variableChipProps: {
            buttonWrapperProps: {
              placement: isDesktop ? "top" : "bottom",
            },
          },
        }}
      />

      <OfferingNotifier
        isOpen={isOfferingNotifyModalOpen}
        onClose={closeOfferingNotifyModal}
        onPrimaryBtnClick={closeOfferingNotifyModal}
      />
    </div>
  );
};
