export const MONTH_DAY_YEAR_TIME_FORMAT = "MMMM dd, yyyy, hh:mm a"; // July 26, 2024, 12:59 PM
export const MONTH_SHORT_DAY_YEAR_TIME_FORMAT = "MMM dd, yyyy, hh:mm a"; // Jul 26, 2024, 12:59 PM
export const MONTH_DAY_YEAR_FORMAT = "MMMM dd, yyyy"; // July 26, 2024
export const MONTH_SHORT_DAY_YEAR_FORMAT = "MMM dd, yyyy"; // Jul 26, 2024
export const MONTH_SHORT_DAY_YEAR_FORMAT_NON_COMMA = "MMM dd yyyy"; // Jun 30 2025
export const DEFAULT_12_HOUR_TIME_FORMAT = "hh:mm aa";
export const DAY_FULL_MONTH_YEAR_FORMAT = "d MMMM yyyy";
export const DATE_MONTH_YEAR_WITH_TIME = "dd MMM yyyy, hh:mma";
export const YEAR_MONTH_DAY_FORMAT = "yyyy-MM-dd";
export const SHORT_MONTH_DATE = "MMM d"; // Jun 11
export const YEAR_MONTH_DAY_TIME_FORMAT = "yyyy-MM-dd hh:mm a"; // 2024-06-20 11:45 AM
export const YEAR_MONTH_DATE_HYPHEN_TIME_FORMAT = "dd MMMM yyyy - hh:mma"; // 18 March 2022 - 04:20PM
export const MONTH_DATE_TIME_FORMAT = "MMM dd, hh:mma"; // Aug 26, 12:52AM
export const DATE_MONTH_YEAR_FORMAT = "dd-MM-yyyy"; // 03-07-2024
export const DATE_MONTH_YEAR_SLASH_FORMAT = "dd/MM/yyyy"; // 03/07/2024
export const TIMESTAMP_FORMAT = "yyyyMMddHHmmss"; // 20240703101250
export const FORMAT_MONTH = "MMM";
export const FORMAT_YEAR = "yyyy";
export const TIME_PICKER_FORMAT = "h:mm a"; // 3:30 PM
export const WHATSAPP_RETRY_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss"; // 2025-02-24 14:30:15
export const QUOTED_DATE_FORMAT = "dd MMM ‘yy"; // 20 Dec ‘24
export const DATE_WITH_DAY_ORDINAL_SUFFIX = "do MMM yyyy"; // 20th Dec 2024
