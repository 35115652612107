import { WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT } from "features/WhatsappTemplate/modules/LanguageSelectionDropdown/LanguageSelectionDropdown.constants";
import { finalFormFieldArrayNameGenerator } from "utils/string";
import { BUTTON_TYPES } from "../modules/TemplateDetails/modules/MessageDetailsForm/modules/TemplateButtonField/modules/ButtonBaseField/constants/ButtonBaseField.constants";

export const TEMPLATE_STEPS = {
  TEMPLATE_SELECTION: 0,
  TEMPLATE_DETAILS: 1,
};

export const TEMPLATE_FORM_KEYS = {
  active_step: "active_step",
  display_title: "display_title",
  template_type: "template_type",
  header_data: "header_data",
  type: "type",
  text: "text",
  footer: "footer",
  locale: "locale",
  body: "body",
  html_body: "html_body",
  approval_status: "approval_status",
  notification_type: "notification_type",
  cta_data: "cta_data",
  phone_number: "phone_number",
  url: "url",
  description: "description",
  cta_radio: "cta_radio",
  parent_comm_title: "parent_comm_title",
};

export const TEMPLATE_FORM_FE_KEYS = {
  header_type: finalFormFieldArrayNameGenerator([
    TEMPLATE_FORM_KEYS.header_data,
    TEMPLATE_FORM_KEYS.type,
  ]),
  header_text: finalFormFieldArrayNameGenerator([
    TEMPLATE_FORM_KEYS.header_data,
    TEMPLATE_FORM_KEYS.text,
  ]),
  cta_radio: "cta_radio",
  selected_template_uuid: "selected_template_uuid",
};

export const TEMPLATE_TYPES = {
  MARKETING: 2,
  UTILITY: 5,
};

export const TEMPLATE_TYPE_OPTIONS = [
  { label: "Marketing", value: TEMPLATE_TYPES.MARKETING },
  { label: "Utility", value: TEMPLATE_TYPES.UTILITY },
];

export const HEADER_TYPES = {
  NONE: 1,
  TEXT: 5,
  IMG: 2,
  VIDEO: 3,
  DOC: 4,
};

export const HEADER_TYPE_OPTIONS = [
  {
    value: HEADER_TYPES.NONE,
    label: "None",
  },
  {
    value: HEADER_TYPES.TEXT,
    label: "Text",
  },
  {
    value: HEADER_TYPES.IMG,
    label: "Img",
  },
  {
    value: HEADER_TYPES.VIDEO,
    label: "Video",
  },
  {
    value: HEADER_TYPES.DOC,
    label: "Doc",
  },
];

export const TEMPLATE_APPROVAL_STATUS = {
  DRAFT: 1,
  UNDER_REVIEW: 2,
  SUBMITTED: 3,
  APPROVED: 4,
  REJECTED: 5,
};

export const TEMPLATE_URL_QUERY_PARAMS = {
  step: "step",
  template: "template",
  ron: "ron", // redirect on success url
  read_only: "ro", // read only mode while viewing template
  pct: "pct", // parent comm title
};

export const TEMPLATE_FORM_INITIAL_STATE = {
  [TEMPLATE_FORM_KEYS.approval_status]: TEMPLATE_APPROVAL_STATUS.UNDER_REVIEW,
  [TEMPLATE_FORM_KEYS.active_step]: TEMPLATE_STEPS.TEMPLATE_SELECTION,
  [TEMPLATE_FORM_KEYS.display_title]: "",
  [TEMPLATE_FORM_KEYS.template_type]: TEMPLATE_TYPES.MARKETING,
  [TEMPLATE_FORM_KEYS.locale]: WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT.value,
  [TEMPLATE_FORM_KEYS.cta_data]: [],
  [TEMPLATE_FORM_KEYS.header_data]: {
    [TEMPLATE_FORM_KEYS.type]: HEADER_TYPES.NONE,
  },
  [TEMPLATE_FORM_FE_KEYS.cta_radio]: BUTTON_TYPES.NONE,
};
