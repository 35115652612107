import {
  TEMPLATE_FORM_KEYS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { finalFormFieldArrayNameGenerator } from "utils/string";
import { ButtonBaseField } from "../ButtonBaseField/ButtonBaseField";
import { BUTTON_TARGET_TYPE } from "../ButtonBaseField/constants/ButtonBaseField.constants";
import { PhoneButtonField } from "../PhoneButtonField/PhoneButtonField";
import { UrlButtonField } from "../UrlButtonField/UrlButtonField";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";

export const ButtonInputField = ({
  name,
  index,
  value,
  disbaleTypeSeletion,
  onDelete,
  showDragHandle,
}) => {
  const { [TEMPLATE_FORM_KEYS.type]: buttonType } = value;
  const isPhoneButton = buttonType === BUTTON_TARGET_TYPE.PHONE_NUMBER;
  const isUrlButton = buttonType === BUTTON_TARGET_TYPE.URL;
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  return (
    <Draggable draggableId={name} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="mb-3"
        >
          <ButtonBaseField
            label={`Button ${index + 1}`}
            dropdownFieldName={finalFormFieldArrayNameGenerator([
              name,
              TEMPLATE_FORM_KEYS.type,
            ])}
            textFieldName={finalFormFieldArrayNameGenerator([
              name,
              TEMPLATE_FORM_KEYS.text,
            ])}
            onDelete={onDelete}
            dragHandleProps={provided.dragHandleProps}
            disableTypeSelection={disbaleTypeSeletion || readOnly}
            showDragHandle={showDragHandle && !readOnly}
          >
            {isPhoneButton && (
              <PhoneButtonField
                name={finalFormFieldArrayNameGenerator([
                  name,
                  TEMPLATE_FORM_KEYS.phone_number,
                ])}
              />
            )}

            {isUrlButton && (
              <UrlButtonField
                name={finalFormFieldArrayNameGenerator([
                  name,
                  TEMPLATE_FORM_KEYS.url,
                ])}
              />
            )}
          </ButtonBaseField>
        </div>
      )}
    </Draggable>
  );
};
