import classnames from "classnames";
import ExlyTableFilters from "common/Components/ExlyTable/ExlyTableFilters";
import React from "react";
import { formatTemplateFilterChips } from "./utils/TemplateFilters.utils";

export const TemplateFilters = ({
  filtersConfig,
  value,
  onChange,
  filterClassName,
}) => {
  return (
    <ExlyTableFilters
      filterValues={value}
      className={classnames("mb-0", filterClassName)}
      filters={filtersConfig}
      tableChipsFormatter={formatTemplateFilterChips}
      setFilters={onChange}
      noExportButton
    />
  );
};
