import CircularProgress from "@material-ui/core/CircularProgress";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import {
  TEMPLATE_FORM_FE_KEYS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { useTemplate } from "features/CreateWhatsappPresetNotificationTemplate/utils/useTemplate";
import React, { useEffect } from "react";
import { useForm } from "react-final-form";
import styles from "./TemplateDetails.module.css";
import { MessageDetailsForm } from "./modules/MessageDetailsForm/MessageDetailsForm";
import { TemplateDetailsForm } from "./modules/TemplateDetailsForm/TemplateDetailsForm";

export const TemplateDetails = () => {
  const form = useForm();
  const { isFetching } = useTemplate();

  useEffect(() => {
    // initialize template uuid to fetch template details
    // this syncs the form data even if page if refreshed or coming directly to the specific step
    const handleInitTemplateUuid = () => {
      const selectedTemplateUuid = form.getFieldState(
        TEMPLATE_FORM_FE_KEYS.selected_template_uuid
      ).value;

      if (selectedTemplateUuid) return; // no need to reinitialize template uuid if already added in form data

      const urlParams = getRAUrlParams();
      const initialTemplateUuid = urlParams.get(
        TEMPLATE_URL_QUERY_PARAMS.template
      );

      if (initialTemplateUuid)
        form.change(
          TEMPLATE_FORM_FE_KEYS.selected_template_uuid,
          initialTemplateUuid
        );
    };

    handleInitTemplateUuid();
  }, [form]);

  if (isFetching)
    return (
      <div className="text-center m-4">
        <CircularProgress />
      </div>
    );

  return (
    <div className={styles.root}>
      <TemplateDetailsForm />

      <MessageDetailsForm />
    </div>
  );
};
