import { WA_TEMPLATE_TARGET_OFFERING_TYPE_LABELS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { WHATSAPP_TEMPLATES_FILTER_KEYS } from "../constants/TemplateFilters.constants";

export const formatTemplateFilterChips = (filter, value) => {
  switch (filter) {
    case WHATSAPP_TEMPLATES_FILTER_KEYS.search:
      return `Name: ${value}`;
    case WHATSAPP_TEMPLATES_FILTER_KEYS.category_type:
      return `Category: ${WA_TEMPLATE_TARGET_OFFERING_TYPE_LABELS[value]}`;
    default:
      return undefined;
  }
};
