import { getRAUrlParams } from "features/Common/utils/url.utils";
import {
  TEMPLATE_FORM_INITIAL_STATE,
  TEMPLATE_FORM_KEYS,
  TEMPLATE_STEPS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React, { useEffect } from "react";
import { useForm, useFormState } from "react-final-form";
import styles from "../../CreateWhatsappPresetNotificationTemplate.module.css";
import { Header } from "../Header/Header";
import { TemplateDetails } from "../TemplateDetails/TemplateDetails";
import { TemplatePreview } from "../TemplatePreview/TemplatePreview";
import { TemplateSelection } from "../TemplateSelection/TemplateSelection";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";

export const TemplateFormContent = () => {
  const isDesktop = useIsDesktop();
  const form = useForm();
  const {
    submitting,
    values: { [TEMPLATE_FORM_KEYS.active_step]: activeStep },
  } = useFormState({
    subscription: { submitting: true, values: true },
  });
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);
  const showHeader = readOnly ? isDesktop : true;

  useEffect(() => {
    const handleSyncFormStep = () => {
      const urlParams = getRAUrlParams();
      const urlStepString = urlParams.get(TEMPLATE_URL_QUERY_PARAMS.step);

      if (urlStepString) {
        const step = +urlStepString;

        if (step === TEMPLATE_STEPS.TEMPLATE_SELECTION) {
          // When using form.reset() in React Final Form, it only resets fields that are part of the <Field> components.
          // However, if you’ve dynamically added values using form.change(), those won’t reset automatically
          form.batch(() => {
            Object.keys(TEMPLATE_FORM_INITIAL_STATE).forEach((key) => {
              form.change(key, TEMPLATE_FORM_INITIAL_STATE[key]);
            });
          });
          // resets mounted fields as well
          form.reset(TEMPLATE_FORM_INITIAL_STATE);
        } else {
          form.change(TEMPLATE_FORM_KEYS.active_step, step); // update active step
        }
      }
    };

    handleSyncFormStep();
  }, [form]);

  return (
    <>
      {showHeader && <Header onSubmit={form.submit} submitting={submitting} />}

      <div className={styles.body}>
        <div className={styles.content_section}>
          {activeStep === TEMPLATE_STEPS.TEMPLATE_SELECTION && (
            <TemplateSelection />
          )}

          {activeStep === TEMPLATE_STEPS.TEMPLATE_DETAILS && (
            <TemplateDetails />
          )}
        </div>

        {isDesktop && <TemplatePreview />}
      </div>
    </>
  );
};
