import { toJpeg } from "html-to-image";
import { logError } from "utils/error";

/**
 * Downloads an HTML element as a JPEG image.
 *
 * This function captures the specified HTML element by its `id` and converts it
 * into a JPEG image using the `toJpeg` function from the `html-to-image` library.
 * The generated image is then automatically downloaded.
 *
 * @param {Object} params - Function parameters.
 * @param {string} params.id - The ID of the HTML element to capture.
 *
 * @returns {void} - The function does not return anything.
 */
export const downloadImage = async ({ id, onStart = () => {} }) => {
  if (!id) {
    return;
  }

  const element = document.getElementById(id);

  if (!element) {
    return;
  }

  try {
    onStart();

    const dataUrl = await toJpeg(element, { cacheBust: true });
    const link = document.createElement("a");
    link.download = "id.jpeg";
    link.href = dataUrl;
    link.click();
  } catch (error) {
    logError({
      error: error || "Image download failed. Please try again.",
      occuredAt: "downloadImage in src/features/Common/utils/media.utils.js",
      when: "while downloading an image",
    });
  }
};
