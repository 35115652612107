import { ExlyDropdown, ExlyInput } from "common/form";
import { FormLabel } from "common/form/FormLabel/FormLabel";
import {
  isAlphaNumberUnderScore,
  maxLength,
  minLength,
  required,
} from "common/validate";
import { validateWhiteSpaces } from "features/Common/modules/Form/Form.utils";
import {
  TEMPLATE_FORM_KEYS,
  TEMPLATE_TYPE_OPTIONS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { WHATSAPP_TEMPLATE_LANGUAGES_OPTIONS } from "features/WhatsappTemplate/modules/LanguageSelectionDropdown/LanguageSelectionDropdown.constants";
import React from "react";
import { Field, useField } from "react-final-form";
import { templateToolTipTitle } from "ui/pages/whatsappBroadcast/create/CustomTemplates/moduleConstants";
import { composeValidators } from "utils/validations";
import { TemplateDetailSection } from "../TemplateDetailSection/TemplateDetailSection";
import styles from "./TemplateDetailsForm.module.css";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";

export const TemplateDetailsForm = () => {
  const templateNameFieldState = useField(TEMPLATE_FORM_KEYS.display_title);
  const templateName = templateNameFieldState.input.value;
  const isTemplateNameValid =
    templateNameFieldState.meta.valid && !!templateName;
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  return (
    <TemplateDetailSection
      completed={isTemplateNameValid}
      title="Template details"
    >
      <div className={styles.root}>
        <div>
          <FormLabel text="Template name" required />
          <Field
            name={TEMPLATE_FORM_KEYS.display_title}
            component={ExlyInput}
            validate={composeValidators(
              required,
              validateWhiteSpaces(),
              isAlphaNumberUnderScore,
              minLength(3, "Title must be atleast 3 characters"),
              maxLength(100, "Title must be atmost 100 characters")
            )}
            placeholder="Enter template name..."
            size="small"
            fullWidth
            disabled={readOnly}
          />
        </div>

        <div>
          <FormLabel
            text="Template category"
            info={templateToolTipTitle}
            required
          />
          <Field
            name={TEMPLATE_FORM_KEYS.template_type}
            component={ExlyDropdown}
            options={TEMPLATE_TYPE_OPTIONS}
            size="small"
            fullWidth="100%"
            disabled={readOnly}
          />
        </div>

        <div>
          <FormLabel text="Template language" required />
          <Field
            name={TEMPLATE_FORM_KEYS.locale}
            options={WHATSAPP_TEMPLATE_LANGUAGES_OPTIONS}
            size="small"
            fullWidth="100%"
            component={ExlyDropdown}
            restMenuProps={{ menu_paper_classname: styles.optionsWrapper }}
            disabled={readOnly}
          />
        </div>
      </div>
    </TemplateDetailSection>
  );
};
