import { maxLength, minLength, required } from "common/validate";
import { composeValidators, validateURLV2 } from "utils/validations";
import {
  TARGET_URL_MAX_LENGTH,
  TARGET_URL_MIN_LENGTH,
} from "../constants/UrlButtonField.constants";

export const validateButtonFieldUrl = composeValidators(
  required,
  validateURLV2(),
  minLength(
    TARGET_URL_MIN_LENGTH,
    `url must be atleast ${TARGET_URL_MIN_LENGTH} characters`
  ),

  maxLength(
    TARGET_URL_MAX_LENGTH,
    `url must be atmost ${TARGET_URL_MAX_LENGTH} characters`
  )
);
