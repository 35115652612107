import { FILTER_TYPES } from "common/Components/ExlyTable/ExlyTableFilters/filtersConstants";
import { WA_TEMPLATE_TARGET_OFFERING_TYPE_OPTIONS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";

export const WHATSAPP_TEMPLATES_FILTER_KEYS = {
  category_type: "category_type",
  search: "search",
  parent_comm_title: "parent_comm_title",
};

export const WHATSAPP_TEMPLATES_FILTER_CONFIG_MAP = {
  [WHATSAPP_TEMPLATES_FILTER_KEYS.search]: {
    source: WHATSAPP_TEMPLATES_FILTER_KEYS.search,
    placeholder: "Name",
    type: "input",
    disableContains: true,
  },
  [WHATSAPP_TEMPLATES_FILTER_KEYS.category_type]: {
    source: WHATSAPP_TEMPLATES_FILTER_KEYS.category_type,
    placeholder: "Category",
    type: FILTER_TYPES.dropdown,
    disableContains: true,
    options: WA_TEMPLATE_TARGET_OFFERING_TYPE_OPTIONS,
  },
};
