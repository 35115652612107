import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";

const { extractPlainText } = RichTextEditorUtils;

export const validatePollDescriptionLength = (value) => {
  const totalCharacters = extractPlainText(value)
    ?.trim()
    ?.replaceAll("\n", "")?.length;

  if (!totalCharacters) return "Required";
};
