import { app_route_keys } from "constants/urlPaths";
import {
  TEMPLATE_APPROVAL_STATUS,
  TEMPLATE_FORM_KEYS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React from "react";
import { useForm, useFormState } from "react-final-form";
import SaveAsDraftModal from "ui/pages/whatsappBroadcast/Components/SaveAsDraftModal";
import { whatsappSubTabsMapping } from "ui/pages/whatsappBroadcast/list/constants";
import { navigateTo } from "utils/urlUtils";

export const SaveDraftTemplateModal = ({ open, templateName, onClose }) => {
  const form = useForm();
  const {
    submitting,
    values: {
      [TEMPLATE_FORM_KEYS.approval_status]: templateApprovalStatus,
    } = {},
  } = useFormState({ subscription: { submitting: true, values: true } });
  const isDrafting =
    submitting && templateApprovalStatus === TEMPLATE_APPROVAL_STATUS.DRAFT;

  const handleSaveAsDraft = () => {
    form.submit().then(onClose);
  };

  const handleBack = () => {
    navigateTo(app_route_keys.template_list, {
      args: whatsappSubTabsMapping.Drafts,
    });
  };

  return (
    <SaveAsDraftModal
      isOpen={open}
      onClose={onClose}
      onPrimaryBtnClick={handleSaveAsDraft}
      onSecondaryBtnClick={handleBack}
      templateName={templateName}
      primaryBtnProps={{
        loading: isDrafting,
        disabled: isDrafting,
      }}
      secondaryBtnProps={{
        disabled: isDrafting,
      }}
    />
  );
};
