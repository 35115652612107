import { IconButton } from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { Button, Progress } from "@my-scoot/component-library-legacy";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import classnames from "classnames";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import { is_empty } from "features/Common/utils/common.utils";
import {
  TEMPLATE_APPROVAL_STATUS,
  TEMPLATE_FORM_FE_KEYS,
  TEMPLATE_FORM_INITIAL_STATE,
  TEMPLATE_FORM_KEYS,
  TEMPLATE_STEPS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { updateTemplateCreationUrl } from "features/CreateWhatsappPresetNotificationTemplate/utils/CreateWhatsappPresetNotificationTemplate.utils";
import React, { useState } from "react";
import { useForm, useFormState } from "react-final-form";
import GuideCarousel from "ui/pages/Seo/components/GuideCarousel/GuideCarousel";
import QuickGuideButton from "ui/pages/Seo/components/QuickGuideButton/QuickGuideButton";
import ApprovalModal from "ui/pages/whatsappBroadcast/Components/ApprovalModal";
import { whatsAppGuideSlides } from "ui/pages/whatsappBroadcast/list/constants";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import { TemplatePreviewModal } from "../TemplatePreview/modules/TemplatePreviewModal/TemplatePreviewModal";
import styles from "./Header.module.css";
import { SaveDraftTemplateModal } from "./modules/SaveDraftTemplateModal/SaveDraftTemplateModal";

export const Header = () => {
  const { notify } = useNotifications();
  const {
    submitting,
    values: {
      [TEMPLATE_FORM_KEYS.approval_status]: templateApprovalStatus,
    } = {},
  } = useFormState({ subscription: { submitting: true, values: true } });
  const isDesktop = useIsDesktop();
  const form = useForm();
  const activeStep = useFormFieldValue(TEMPLATE_FORM_KEYS.active_step);
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);
  const [isGuideModalOpen, openGuideModal, hideGuideModal] =
    useToggleState(false);
  const [isApprovalModalOpen, openApprovalModal, closeApprovalModal] =
    useToggleState(false);
  const [
    isTemplatePreviewModalOpen,
    openTemplatePreviewModal,
    closeTemplatePreviewModal,
  ] = useToggleState(false);
  const [draftModalState, setDraftModalState] = useState({
    open: false,
    templateName: "",
  });
  const isSubmitting =
    submitting &&
    templateApprovalStatus === TEMPLATE_APPROVAL_STATUS.UNDER_REVIEW;

  const handleClick = (e, step) => {
    if (step === TEMPLATE_STEPS.TEMPLATE_SELECTION) {
      // When using form.reset() in React Final Form, it only resets fields that are part of the <Field> components.
      // However, if you’ve dynamically added values using form.change(), those won’t reset automatically
      form.batch(() => {
        Object.keys(TEMPLATE_FORM_INITIAL_STATE).forEach((key) => {
          form.change(key, TEMPLATE_FORM_INITIAL_STATE[key]);
        });
      });
      // resets mounted fields as well
      form.reset(TEMPLATE_FORM_INITIAL_STATE);
      updateTemplateCreationUrl({
        activeStep: TEMPLATE_STEPS.TEMPLATE_SELECTION,
      });
    }
  };

  const handleOpenDraftModal = () => {
    const templateName = form.getFieldState(
      TEMPLATE_FORM_KEYS.display_title
    ).value;
    form.change(
      TEMPLATE_FORM_KEYS.approval_status,
      TEMPLATE_APPROVAL_STATUS.DRAFT
    );
    setDraftModalState({ open: true, templateName });
  };

  const handleCloseDraftModal = () => {
    setDraftModalState({ open: false, templateName: "" });
    form.change(
      TEMPLATE_FORM_KEYS.approval_status,
      TEMPLATE_APPROVAL_STATUS.UNDER_REVIEW
    );
  };

  const handleBack = () => {
    const activeStep = form.getFieldState(TEMPLATE_FORM_KEYS.active_step).value;

    if (activeStep === TEMPLATE_STEPS.TEMPLATE_SELECTION) {
      window.history.go(-1);
      return;
    }

    // determine if any field is dirty
    const isAnyFieldDirty = [
      TEMPLATE_FORM_KEYS.display_title,
      TEMPLATE_FORM_KEYS.template_type,
      TEMPLATE_FORM_KEYS.locale,
      TEMPLATE_FORM_KEYS.description,
      TEMPLATE_FORM_KEYS.footer,
      TEMPLATE_FORM_KEYS.cta_data,
      TEMPLATE_FORM_FE_KEYS.header_type,
      TEMPLATE_FORM_FE_KEYS.header_text,
    ].some((formKey) => {
      const fieldState = form.getFieldState(formKey);
      return !!fieldState && fieldState.dirty;
    });

    if (isAnyFieldDirty) handleOpenDraftModal();
    else window.history.go(-1);
  };

  const handleSubmitForApproval = () => {
    const errors = form.getState().errors;

    if (!is_empty(errors)) {
      // if the form is invalid, trigger submission to display errors on the UI
      form.submit();
      notify(
        "Please complete the form correctly",
        notification_color_keys.error
      );
    } else openApprovalModal(); // open approval modal if form values are valid
  };

  const handleSubmitValidatedForm = () => {
    form.submit().then(closeApprovalModal);
  };

  return (
    <div className={styles.root}>
      <div className={classnames("align-items-center", styles.label)}>
        <IconButton
          className="p-0"
          onClick={handleBack}
          disabled={isSubmitting}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
        <strong>
          {readOnly ? null : "Create"} Preset Notification Template
        </strong>
      </div>

      {readOnly ? null : (
        <>
          <div className={styles.progress_container}>
            <Progress
              variant="alternate"
              options={["Select Notification", "Campaign details"]}
              onClick={handleClick}
              activeStep={activeStep}
              hideProgress
            />
          </div>

          {activeStep === TEMPLATE_STEPS.TEMPLATE_DETAILS && (
            <>
              <div className="d-flex align-items-center">
                {isDesktop && (
                  <QuickGuideButton
                    openGuideModal={openGuideModal}
                    steps={[]}
                  />
                )}

                <div className={styles.actions}>
                  {!isDesktop && (
                    <Button
                      color="subtlePrimary"
                      variant="text"
                      fullWidth={!isDesktop}
                      onClick={openTemplatePreviewModal}
                    >
                      Preview
                    </Button>
                  )}

                  <Button
                    className="ml-2"
                    onClick={handleSubmitForApproval}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    fullWidth={!isDesktop}
                  >
                    Send for approval
                  </Button>
                </div>
              </div>

              <GuideCarousel
                open={isGuideModalOpen}
                slides={whatsAppGuideSlides(isDesktop)}
                onClose={hideGuideModal}
              />

              <SaveDraftTemplateModal
                open={draftModalState.open}
                templateName={draftModalState.templateName}
                onClose={handleCloseDraftModal}
              />

              <ApprovalModal
                isOpen={isApprovalModalOpen}
                onClose={closeApprovalModal}
                onPrimaryBtnClick={handleSubmitValidatedForm}
                primaryBtnProps={{
                  loading: isSubmitting,
                  disabled: isSubmitting,
                }}
                secondaryBtnProps={{
                  disabled: isSubmitting,
                }}
              />

              <TemplatePreviewModal
                open={isTemplatePreviewModalOpen}
                onClose={closeTemplatePreviewModal}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
