import { MutableRefObject } from "react";

const useInsertTextAtCursor = ({
  inputRef,
}: {
  inputRef: MutableRefObject<HTMLInputElement>;
}) => {
  const insertTextAtCursor = (text: string) => {
    const input = inputRef.current;

    if (input) {
      const startPos = input.selectionStart || 0;
      const endPos = input.selectionEnd || 0;
      const newPos = startPos + text.length; // Calculate the new cursor position
      const newValue =
        input.value.substring(0, startPos) +
        text +
        input.value.substring(endPos, input.value.length);

      // Update the input's value using Object.defineProperty
      // this triggers the onChange function of target input component on value change
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      )?.set?.call(input, newValue);

      // Using setTimeout to ensure the cursor position is set after state update
      setTimeout(() => {
        input.setSelectionRange(newPos, newPos);
      }, 0);

      // Trigger the change event for controlled components
      input.dispatchEvent(new Event("input", { bubbles: true }));
    }
  };

  return {
    inputRef,
    insertTextAtCursor,
  };
};

export default useInsertTextAtCursor;
