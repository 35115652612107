import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import React from "react";
import { TemplatePreviewContent } from "../TemplatePreviewContent/TemplatePreviewContent";
import styles from "./TemplatePreviewModal.module.css";

export const TemplatePreviewModal = ({ open, onClose }) => {
  return (
    <ExlyModal
      title={
        <div>
          <div>Preview</div>
          <div className={styles.info}>
            This is how your message will look like
          </div>
        </div>
      }
      open={open}
      onClose={onClose}
      className={styles.root}
      customFooter={<></>}
    >
      <TemplatePreviewContent />
    </ExlyModal>
  );
};
