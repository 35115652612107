import Button from "@material-ui/core/Button";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import classnames from "classnames";
import { ExlyDropdown, ExlyInput } from "common/form";
import { FormLabel } from "common/form/FormLabel/FormLabel";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { Field } from "react-final-form";
import styles from "./ButtonBaseField.module.css";
import {
  BUTTON_TARGET_OPTIONS,
  BUTTON_TEXT_MAX_LENGTH,
} from "./constants/ButtonBaseField.constants";
import { ButtonWordLimitViewer } from "./modules/ButtonWordLimitViewer/ButtonWordLimitViewer";
import { validateButtonText } from "./utils/ButtonBaseField.validators";
import { TEMPLATE_URL_QUERY_PARAMS } from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";

export const ButtonBaseField = ({
  label,
  dropdownFieldName,
  textFieldName,
  children,
  onDelete,
  dragHandleProps,
  disableTypeSelection,
  showDragHandle,
}) => {
  const textValue = useFormFieldValue(textFieldName);
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  return (
    <div className="d-flex align-items-center">
      <div className="flex-grow-1">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>{label}</div>
          {!readOnly && (
            <Button
              className={classnames("p-0 text-danger", styles.remove)}
              onClick={onDelete}
            >
              Remove
            </Button>
          )}
        </div>

        <div className={styles.body}>
          <FormLabel text="Type" />
          <div className={styles.fields}>
            <div className={styles.dropdownWrapper}>
              <Field
                name={dropdownFieldName}
                options={BUTTON_TARGET_OPTIONS}
                size="small"
                component={ExlyDropdown}
                fullWidth
                disabled={disableTypeSelection}
                className={styles.dropdown}
              />
            </div>

            <div className="flex-grow-1">
              <Field
                name={textFieldName}
                size="small"
                component={ExlyInput}
                placeholder="Enter text..."
                className={classnames("flex-1", styles.whiteBackground)}
                fullWidth
                validate={validateButtonText}
                endAdornment={
                  <ButtonWordLimitViewer
                    counter={textValue?.length || 0}
                    limit={BUTTON_TEXT_MAX_LENGTH}
                  />
                }
                disabled={readOnly}
              />
            </div>
          </div>

          <div>{children}</div>
        </div>
      </div>

      {showDragHandle && (
        <div className={styles.drag_handle} {...dragHandleProps}>
          <DragIndicatorIcon />
        </div>
      )}
    </div>
  );
};
