import { TEMPLATE_FORM_KEYS } from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React from "react";
import { useField } from "react-final-form";
import { TemplateDetailSection } from "../TemplateDetailSection/TemplateDetailSection";
import styles from "./MessageDetailsForm.module.css";
import { TemplateBodyField } from "./modules/TemplateBodyField/TemplateBodyField";
import { TemplateButtonField } from "./modules/TemplateButtonField/TemplateButtonField";
import { TemplateFooterField } from "./modules/TemplateFooterField/TemplateFooterField";
import { TemplateHeaderField } from "./modules/TemplateHeaderField/TemplateHeaderField";

export const MessageDetailsForm = () => {
  const isDescriptionValid = useField(TEMPLATE_FORM_KEYS.description).meta
    .valid;
  const isHeaderDataValid = useField(TEMPLATE_FORM_KEYS.header_data).meta.valid;
  const isCtaDataValid = useField(TEMPLATE_FORM_KEYS.cta_data).meta.valid;
  const isSectionValid =
    isDescriptionValid && isCtaDataValid && isHeaderDataValid;

  return (
    <TemplateDetailSection completed={isSectionValid} title="Message details">
      <div className={styles.root}>
        <TemplateHeaderField />

        <TemplateBodyField />

        <TemplateFooterField />

        <TemplateButtonField />
      </div>
    </TemplateDetailSection>
  );
};
