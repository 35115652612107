import { maxLength, minLength, required } from "common/validate";
import { composeValidators } from "utils/validations";
import {
  TEMPLATE_HEADER_MAX_LENGTH,
  TEMPLATE_HEADER_MIN_LENGTH,
} from "../constants/TemplateHeaderField.constants";

export const validateTemplateHeader = composeValidators(
  required,
  minLength(
    TEMPLATE_HEADER_MIN_LENGTH,
    `Header must be atleast ${TEMPLATE_HEADER_MIN_LENGTH} characters`
  ),
  maxLength(
    TEMPLATE_HEADER_MAX_LENGTH,
    `Header must be atmost ${TEMPLATE_HEADER_MAX_LENGTH} characters`
  )
);
