import React from "react";
import styles from "./ButtonWordLimitViewer.module.css";

export const ButtonWordLimitViewer = ({ counter, limit }) => {
  return (
    <div className={styles.root}>
      <span>
        {counter || 0}/{limit}
      </span>
    </div>
  );
};
