import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import { AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { WhatsappTemplatesList } from "features/AutomatedCommunication/modules/EditAutomatedCommunication/modules/CommActions/modules/ChangeWhatsAppTemplate/modules/WhatsappTemplatesList/WhatsappTemplatesList";
import { fetchWhatsappPresetNotificationsTemplates } from "features/AutomatedCommunication/utils/AutomatedCommunication.apiCalls";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import {
  TEMPLATE_FORM_FE_KEYS,
  TEMPLATE_FORM_KEYS,
  TEMPLATE_STEPS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { TemplatePreviewModal } from "features/CreateWhatsappPresetNotificationTemplate/modules/TemplatePreview/modules/TemplatePreviewModal/TemplatePreviewModal";
import { updateTemplateCreationUrl } from "features/CreateWhatsappPresetNotificationTemplate/utils/CreateWhatsappPresetNotificationTemplate.utils";
import React, { useState } from "react";
import { useForm } from "react-final-form";
import { useToggleState } from "utils/hooks";
import { WHATSAPP_TEMPLATES_FILTER_KEYS } from "./modules/TemplateFilters/constants/TemplateFilters.constants";
import { TemplateFilters } from "./modules/TemplateFilters/TemplateFilters";
import styles from "./TemplatesList.module.css";
import { getTemplatesListFilterConfig } from "./utils/TemplatesList.utils";

export const TemplatesList = () => {
  const isDesktop = useIsDesktop();
  const urlParams = getRAUrlParams();
  const parentCommTitle = urlParams.get(TEMPLATE_URL_QUERY_PARAMS.pct);
  const [filters, setFilters] = useState({});
  const form = useForm();
  const [isPreviewModalOpen, openPreviewModal, closePreviewModal] =
    useToggleState(false);

  const handlePreview = (template) => {
    const { uuid } = template;
    form.change(TEMPLATE_FORM_FE_KEYS.selected_template_uuid, uuid);

    if (!isDesktop) openPreviewModal();
  };

  const handleSelectTemplate = (template) => {
    const {
      [AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.uuid]: uuid,
      [AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.title]: parentCommTitle,
    } = template;

    form.batch(() => {
      form.change(TEMPLATE_FORM_FE_KEYS.selected_template_uuid, uuid);
      form.change(
        TEMPLATE_FORM_KEYS.active_step,
        TEMPLATE_STEPS.TEMPLATE_DETAILS
      );
      form.change(TEMPLATE_FORM_KEYS.parent_comm_title, parentCommTitle);
    });

    updateTemplateCreationUrl({
      activeStep: TEMPLATE_STEPS.TEMPLATE_DETAILS,
      templateUuid: uuid,
    });
  };

  return (
    <div>
      <TemplateFilters
        filtersConfig={getTemplatesListFilterConfig({ parentCommTitle })}
        value={filters}
        onChange={setFilters}
      />

      <div className={styles.list}>
        <WhatsappTemplatesList
          open
          api={AUTOMATED_COMMS_APIS.fetch_comms_listings}
          queryFn={fetchWhatsappPresetNotificationsTemplates}
          filters={{
            ...filters,
            [WHATSAPP_TEMPLATES_FILTER_KEYS.parent_comm_title]: parentCommTitle,
          }}
          onPreview={handlePreview}
          onChoose={handleSelectTemplate}
        />

        {!isDesktop && (
          <TemplatePreviewModal
            open={isPreviewModalOpen}
            onClose={closePreviewModal}
          />
        )}
      </div>
    </div>
  );
};
