import ExlyRadioGroup from "common/form/ExlyRadioGroup/Index";
import { FormLabel } from "common/form/FormLabel/FormLabel";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import {
  TEMPLATE_FORM_FE_KEYS,
  TEMPLATE_FORM_KEYS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React from "react";
import { Field, useForm } from "react-final-form";
import {
  BUTTON_TARGET_TYPE,
  BUTTON_TYPE_OPTIONS,
  BUTTON_TYPES,
} from "./modules/ButtonBaseField/constants/ButtonBaseField.constants";
import { ButtonsInputField } from "./modules/ButtonsInputField/ButtonsInputField";

export const TemplateButtonField = () => {
  const form = useForm();
  const buttonType = useFormFieldValue(TEMPLATE_FORM_FE_KEYS.cta_radio);
  const isButtonTypeCta = buttonType === BUTTON_TYPES.CALL_TO_ACTION;
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  const handleButtonTypeChange = (value) => {
    if (value === BUTTON_TYPES.CALL_TO_ACTION) {
      form.change(TEMPLATE_FORM_KEYS.cta_data, [
        {
          [TEMPLATE_FORM_KEYS.text]: "",
          [TEMPLATE_FORM_KEYS.type]: BUTTON_TARGET_TYPE.URL,
        },
      ]);
    } else {
      form.change(TEMPLATE_FORM_KEYS.cta_data, []);
    }
  };

  return (
    <div>
      <FormLabel text="Button" required />
      <Field
        name={TEMPLATE_FORM_FE_KEYS.cta_radio}
        component={ExlyRadioGroup}
        onChange={handleButtonTypeChange}
        options={BUTTON_TYPE_OPTIONS}
        isRow
        disabled={readOnly}
      />

      {isButtonTypeCta && <ButtonsInputField />}
    </div>
  );
};
