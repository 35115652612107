import { Button } from "@material-ui/core";
import AddRounded from "@material-ui/icons/AddRounded";
import classnames from "classnames";
import {
  TEMPLATE_FORM_KEYS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { BUTTON_TARGET_TYPE } from "../ButtonBaseField/constants/ButtonBaseField.constants";
import { ButtonInputField } from "../ButtonInputField/ButtonInputField";
import styles from "./ButtonsInputField.module.css";
import { MAX_BUTTONS_ALLOWED } from "./constants/ButtonsInputField.constants";
import { validateButtonsField } from "./utils/ButtonsInputField.validators";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";

export const ButtonsInputField = () => {
  const form = useForm();
  const {
    mutators: { push, remove },
  } = useForm();
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  const handleDragEnd = (fields) => (result) => {
    // dropped outside the list
    if (!result.destination) return;
    if (result.destination.index === -1) return;

    fields.move(result.source.index, result.destination.index);
  };

  const handleAddNewButton = () => {
    const ctaData = form.getFieldState(TEMPLATE_FORM_KEYS.cta_data).value;
    const [firstCtaData = {}] = ctaData;
    const { [TEMPLATE_FORM_KEYS.type]: firstCtaType } = firstCtaData;
    const isButtonTypeUrl = firstCtaType === BUTTON_TARGET_TYPE.URL;

    push(TEMPLATE_FORM_KEYS.cta_data, {
      [TEMPLATE_FORM_KEYS.text]: "",
      [TEMPLATE_FORM_KEYS.type]: isButtonTypeUrl
        ? BUTTON_TARGET_TYPE.PHONE_NUMBER
        : BUTTON_TARGET_TYPE.URL,
    });
  };

  const handleRemoveButton = (index) => () => {
    remove(TEMPLATE_FORM_KEYS.cta_data, index);
  };

  return (
    <FieldArray
      name={TEMPLATE_FORM_KEYS.cta_data}
      validate={validateButtonsField}
    >
      {({ fields }) => (
        <DragDropContext onDragEnd={handleDragEnd(fields)}>
          <Droppable droppableId={TEMPLATE_FORM_KEYS.cta_data}>
            {(provided) => (
              <>
                <div ref={provided.innerRef}>
                  {fields.map((name, index) => (
                    <ButtonInputField
                      key={name}
                      name={name}
                      index={index}
                      value={fields.value[index]}
                      disbaleTypeSeletion={fields.length >= MAX_BUTTONS_ALLOWED}
                      onDelete={handleRemoveButton(index)}
                      showDragHandle={fields.length >= MAX_BUTTONS_ALLOWED}
                    />
                  ))}
                  {provided.placeholder}
                </div>

                {fields.length < MAX_BUTTONS_ALLOWED && !readOnly && (
                  <div>
                    <Button
                      startIcon={<AddRounded />}
                      onClick={handleAddNewButton}
                      className={classnames("p-0", styles.add_cta)}
                      classes={{ startIcon: "mr-0" }}
                    >
                      Add Button
                    </Button>

                    <div className={classnames("mt-2", styles.notification)}>
                      You can only add 2 buttons in total. One button of each
                      type
                    </div>
                  </div>
                )}
              </>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </FieldArray>
  );
};
