import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { updateQueryStringParameter } from "common/util";
import { checkIsArrayEmpty } from "features/Common/modules/DataTypes/utils/Array.utils";
import { CUSTOM_TEMPLATE_TYPES } from "ui/pages/whatsappBroadcast/modules/CreateCustomTemplateModal/constants/CreateCustomTemplateModal.constants";
import { appendUrlParams } from "utils/urlUtils";
import { getStringFromHtml } from "utils/Utils";
import {
  TEMPLATE_FORM_KEYS,
  TEMPLATE_STEPS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "../constants/CreateWhatsappPresetNotificationTemplate.constants";
import {
  CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE,
  CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE_ROUTE_IDS,
} from "../constants/CreateWhatsappPresetNotificationTemplate.urlPaths";

const { getHtmlFromEditorState } = RichTextEditorUtils;

export const updateTemplateCreationUrl = ({ activeStep, templateUuid }) => {
  let baseUrl = window.location.href;
  baseUrl = updateQueryStringParameter(
    baseUrl,
    TEMPLATE_URL_QUERY_PARAMS.step,
    activeStep
  );
  baseUrl = updateQueryStringParameter(
    baseUrl,
    TEMPLATE_URL_QUERY_PARAMS.template,
    templateUuid || ""
  );
  window.location.href = baseUrl;
};

export const redirectToCreatePresetNotificationTemplates = ({
  history,
  step = TEMPLATE_STEPS.TEMPLATE_SELECTION,
  template,
  redirectUrlOnSuccess,
  readOnly,
  parentCommTitle,
} = {}) => {
  history.push(
    appendUrlParams(
      CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE_ROUTE_IDS[
        CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE
          .CREATE_PRESET_NOTIFICATION_TEMPLATE
      ],
      {
        [TEMPLATE_URL_QUERY_PARAMS.step]: step,
        [TEMPLATE_URL_QUERY_PARAMS.template]: template,
        [TEMPLATE_URL_QUERY_PARAMS.ron]: redirectUrlOnSuccess,
        [TEMPLATE_URL_QUERY_PARAMS.read_only]: readOnly,
        [TEMPLATE_URL_QUERY_PARAMS.pct]: parentCommTitle,
      }
    )
  );
};

export const formatFormPayload = (values) => {
  const {
    [TEMPLATE_FORM_KEYS.approval_status]: templateApprovalStatus,
    [TEMPLATE_FORM_KEYS.display_title]: templateName,
    [TEMPLATE_FORM_KEYS.template_type]: templateType,
    [TEMPLATE_FORM_KEYS.locale]: templateLanguage,
    [TEMPLATE_FORM_KEYS.header_data]: templateHeaderData,
    [TEMPLATE_FORM_KEYS.description]: rteBodyState,
    [TEMPLATE_FORM_KEYS.footer]: templateFooter,
    [TEMPLATE_FORM_KEYS.cta_radio]: templateButtonType,
    [TEMPLATE_FORM_KEYS.cta_data]: templateCtaData,
    [TEMPLATE_FORM_KEYS.parent_comm_title]: parentCommTitle,
  } = values;

  const htmlBody = getHtmlFromEditorState(rteBodyState);

  return {
    [TEMPLATE_FORM_KEYS.parent_comm_title]: parentCommTitle,
    [TEMPLATE_FORM_KEYS.approval_status]: templateApprovalStatus,
    [TEMPLATE_FORM_KEYS.display_title]: templateName,
    [TEMPLATE_FORM_KEYS.template_type]: templateType,
    [TEMPLATE_FORM_KEYS.locale]: templateLanguage,
    [TEMPLATE_FORM_KEYS.header_data]: templateHeaderData,
    [TEMPLATE_FORM_KEYS.body]: getStringFromHtml(htmlBody),
    [TEMPLATE_FORM_KEYS.html_body]: htmlBody,
    [TEMPLATE_FORM_KEYS.footer]: templateFooter,
    [TEMPLATE_FORM_KEYS.cta_radio]: templateButtonType,
    [TEMPLATE_FORM_KEYS.cta_data]: checkIsArrayEmpty(templateCtaData)
      ? undefined
      : templateCtaData,
    [TEMPLATE_FORM_KEYS.notification_type]:
      CUSTOM_TEMPLATE_TYPES.PRESET_NOTIFICATION,
  };
};
