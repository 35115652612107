// if: lines of code > 300
// then: breakdown this file and move to src/features/Form/utils folder

// @DEVS following three utils (destringifyKeys,stringifyKeys,createFormKeyWithPrefix) are created to handle the restriction of react-final-form, that does not allows us to use numerics as keys in an object https://final-form.org/docs/react-final-form/faq#why-cant-i-have-numeric-keys-in-an-object
/**
 * Destringifies keys in an object based on the specified substring.
 * @param {object} valuesObj - The object with stringified keys.
 * @param {string} substring - The substring used for stringification.
 * @returns {object} - The object with destringified keys.
 */
export const destringifyKeys = (valuesObj, substring) => {
  // Check if valuesObj is valid (not null or undefined) and is an object
  if (!valuesObj || typeof valuesObj !== "object") {
    return {}; // Return an empty object if the input is invalid
  }

  // Proceed with the original logic if the input is valid
  return Object.keys(valuesObj).reduce((result, key) => {
    const resultKey = key.substring(substring.length);
    result[resultKey] = valuesObj[key];
    return result;
  }, {});
};

/**
 * Stringifies keys in an object by appending a prefix.
 * @param {object} valuesObj - The object with keys to be stringified.
 * @param {string} substring - The substring used for stringification.
 * @returns {object} - The object with stringified keys.
 */
export const stringifyKeys = (valuesObj, substring) =>
  Object.keys(valuesObj).reduce((result, key) => {
    result[`${substring}${key}`] = valuesObj[key];
    return result;
  }, {});

/**
 * Concatenates a given key with a prefix to create a form field key.
 * @param {string} key - The key to be concatenated with the prefix.
 * @param {string} substring - The substring used as prefix.
 * @returns {string} The concatenated form field key.
 */
export const createFormKeyWithPrefix = (key, substring) => `${substring}${key}`;

/**
 * Validates if the input string contains only whitespace characters.
 *
 * @param {string} value - The input string to be validated.
 * @returns {string|undefined} - Returns an error message if the input contains only whitespace characters, otherwise undefined.
 */
export const validateWhiteSpaces =
  (message = undefined) =>
  (value) => {
    if (value && value.trim().length === 0)
      return (
        message ??
        "Whitespace characters only are not allowed. Please enter valid input"
      );
  };
