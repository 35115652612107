import CheckCircle from "@material-ui/icons/CheckCircle";
import { Accordion } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import React from "react";
import styles from "./TemplateDetailSection.module.css";

export const TemplateDetailSection = ({ title, completed, children }) => {
  return (
    <Accordion
      expandIconPosition="end"
      defaultExpanded
      accordionSummaryContent={
        <span className={styles.titleWrapper}>
          <CheckCircle
            className={classnames(styles.check, completed && styles.completed)}
          />
          {title}
        </span>
      }
    >
      <div className={styles.body}>{children}</div>
    </Accordion>
  );
};
