import { ExlyInput } from "common/form";
import { FormLabel } from "common/form/FormLabel/FormLabel";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { Field } from "react-final-form";
import { ButtonWordLimitViewer } from "../ButtonBaseField/modules/ButtonWordLimitViewer/ButtonWordLimitViewer";
import { TARGET_URL_MAX_LENGTH } from "./constants/UrlButtonField.constants";
import styles from "./UrlButtonField.module.css";
import { validateButtonFieldUrl } from "./utils/UrlButtonField.validators";
import { TEMPLATE_URL_QUERY_PARAMS } from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";

export const UrlButtonField = ({ name }) => {
  const url = useFormFieldValue(name);
  const readOnly = useFormFieldValue(TEMPLATE_URL_QUERY_PARAMS.read_only);

  return (
    <>
      <FormLabel text="Url" required />
      <Field
        name={name}
        size="small"
        component={ExlyInput}
        placeholder="Paste link or url"
        className={styles.input}
        validate={validateButtonFieldUrl}
        endAdornment={
          <ButtonWordLimitViewer
            counter={url?.length || 0}
            limit={TARGET_URL_MAX_LENGTH}
          />
        }
        disabled={readOnly}
      />
    </>
  );
};
