import classnames from "classnames";
import ExlyImage from "common/Components/ExlyImage";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import React from "react";
import styles from "./ApprovalAwatingModal.module.css";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

export const ApprovalAwatingModal = ({ open, onClose, onPrimaryBtnClick }) => {
  return (
    <ExlyModal
      open={open}
      onClose={onClose}
      customHeader={<></>}
      showSecondaryBtn={false}
      primaryBtnProps={{ fullWidth: true }}
      className={classnames("text-center", styles.root)}
      modal_props={{ modalPaperClassName: styles.paper }}
      primaryBtnText="Okay, Got it"
      onPrimaryBtnClick={onPrimaryBtnClick}
    >
      <div className="text-center">
        <ExlyImage
          src={getS3AssetCDNUrl(
            "assets/vectors/whatsapp_broadcast/approval.svg"
          )}
        />
        <div className={styles.label}>
          The modified message is awaiting approval. You can use that message
          here by clicking “Change Template” once it is approved!
        </div>
      </div>
    </ExlyModal>
  );
};
