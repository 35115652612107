import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { app_route_keys } from "constants/urlPaths";
import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import {
  COMM_EDIT_STATUS,
  EDIT_COMM_URL_PARAMS,
} from "features/AutomatedCommunication/modules/EditAutomatedCommunication/constants/EditAutomatedCommunication.constants";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import arrayMutators from "final-form-arrays";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { whatsapp_broadcasts_apis } from "ui/pages/whatsappBroadcast/whatsappBroadCast.api";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { navigateTo } from "utils/urlUtils";
import {
  TEMPLATE_FORM_INITIAL_STATE,
  TEMPLATE_FORM_KEYS,
  TEMPLATE_STEPS,
  TEMPLATE_URL_QUERY_PARAMS,
} from "./constants/CreateWhatsappPresetNotificationTemplate.constants";
import { TemplateFormContent } from "./modules/TemplateFormContent/TemplateFormContent";
import { formatFormPayload } from "./utils/CreateWhatsappPresetNotificationTemplate.utils";

export const CreateWhatsappPresetNotificationTemplate =
  withComponentLibraryTheme(() => {
    const { notify } = useNotifications();
    const urlParams = getRAUrlParams();
    const initialActiveStep = urlParams.get(TEMPLATE_URL_QUERY_PARAMS.step);
    const readOnly = urlParams.get(TEMPLATE_URL_QUERY_PARAMS.read_only);
    const parentCommTitle = urlParams.get(TEMPLATE_URL_QUERY_PARAMS.pct);

    // memoizing initial values to prevent form state from getting reset on changing url query params
    const [initialValues] = useState({
      ...TEMPLATE_FORM_INITIAL_STATE,
      [TEMPLATE_FORM_KEYS.active_step]: initialActiveStep
        ? +initialActiveStep
        : TEMPLATE_STEPS.TEMPLATE_SELECTION,
      [TEMPLATE_FORM_KEYS.parent_comm_title]: parentCommTitle,
      [TEMPLATE_URL_QUERY_PARAMS.read_only]: readOnly,
    });

    const { mutate: createNewTemplate } = useCustomMutation({
      url: whatsapp_broadcasts_apis.create_new_template,
      queryKey: [whatsapp_broadcasts_apis.create_new_template],
      onError: null,
    });

    useAppLayoutControl({
      values: {
        title: "Create Preset Notification Template",
        showBottomNav: false,
      },
      control: {
        appBar: false,
        bottomNav: false,
        title: true,
      },
    });

    const handleSendForApproval = (values) => {
      const { [TEMPLATE_FORM_KEYS.approval_status]: templateApprovalStatus } =
        values;

      const handleSuccess = () => {
        const redirectionUrl = urlParams.get(TEMPLATE_URL_QUERY_PARAMS.ron);

        if (redirectionUrl) {
          const url = decodeURIComponent(redirectionUrl);
          window.location.replace(
            `${url}&${EDIT_COMM_URL_PARAMS.ces}=${COMM_EDIT_STATUS.SUCCESS}`
          );
        } else {
          navigateTo(app_route_keys.template_list, {
            args: templateApprovalStatus,
          });
        }
      };

      const handleError = (error) => {
        notify(
          error?.response?.data?.message || SOMETHING_WENT_WRONG,
          notification_color_keys.error
        );
      };

      return new Promise((onSuccess, onError) =>
        createNewTemplate(formatFormPayload(values), { onSuccess, onError })
      )
        .then(handleSuccess)
        .catch(handleError);
    };

    return (
      <Form
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={handleSendForApproval}
        initialValues={initialValues}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TemplateFormContent />
          </form>
        )}
      </Form>
    );
  });
