import React from "react";
import { openPricingPage } from "utils/Utils";
import style from "./FeaturePageV2Styles.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import { SUBSCRIPTION_PLAN } from "constants/account.constants";
import {
  featuresDescription,
  featuresHeading,
} from "ui/pages/FeaturePage/FeaturePage.constants.js";
import { is_empty } from "features/Common/utils/common.utils";
import classnames from "classnames";
import ExlyImage from "../ExlyImage/ExlyImage";

export const FeaturePageV2 = ({
  featureName,
  desktopImage,
  mobileImage,
  ctaText,
  classes = {},
  featureJSX,
}) => {
  const isDesktop = useDesktopMediaQuery();
  return (
    <div
      className={classnames(style.featureContainer, classes?.featureContainer)}
    >
      <ExlyImage
        src={isDesktop ? desktopImage : mobileImage}
        alt={featureName}
        className={style.featureImage}
      />
      {featureJSX ?? (
        <div className={style.featureTextContainer}>
          <div className={style.innerBox}>
            <LockRoundedIcon className={style.lockIcon} />
            <div className={style.heading}>{featuresHeading[featureName]}</div>
            <div className={`${style.description} mt-1`}>
              {featuresDescription[featureName]}
            </div>
            <div
              className={`${style.upgradeBtn} mt-3`}
              onClick={openPricingPage}
            >
              {!is_empty(ctaText)
                ? ctaText
                : `Upgrade to ${SUBSCRIPTION_PLAN.PRO}/${SUBSCRIPTION_PLAN.PREMIUM}`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
