import React from "react";

import { MobileModal, Modal } from "@my-scoot/component-library-legacy";

import classnames from "classnames";

import styles from "./ExlyModal.module.css";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";

export const ExlyModal = (props) => {
  const {
    children,
    open,
    title,
    onClose,
    primaryBtnText,
    customFooter,
    customHeader,
    onPrimaryBtnClick,
    primaryBtnProps,
    secondaryBtnProps,
    primaryBtnFullWidth,
    showSecondaryBtn,
    secondaryBtnText = "Cancel",
    onSecondaryBtnClick: onSecondaryBtnClickProp,
    onClickCross,
    modal_props = {},
    mobile_modal_props = {},
    padded = true,
    backDropClickClose,
    escapeKeyDownClose,
    className,
    onScroll,
    contentRef,
    desktopModalWidth = "",
    primaryBtnClassName,
    secondaryBtnClassName,
    id,
  } = props;

  const isDesktop = useDesktopMediaQuery();

  const onSecondaryBtnClick = onSecondaryBtnClickProp || onClose;

  const handleClose = (event, reason) => {
    if (reason && !backDropClickClose && reason === "backdropClick") return;
    if (reason && !escapeKeyDownClose && reason === "escapeKeyDown") return;
    onClose(event, reason);
  };

  return isDesktop ? (
    <Modal
      open={open}
      title={title}
      primaryBtnFullWidth={primaryBtnFullWidth}
      showSecondaryBtn={showSecondaryBtn}
      onClose={handleClose}
      onSecondaryBtnClick={onSecondaryBtnClick}
      primaryBtnText={primaryBtnText}
      onPrimaryBtnClick={onPrimaryBtnClick}
      primaryBtnProps={primaryBtnProps}
      secondaryBtnProps={secondaryBtnProps}
      secondaryBtnText={secondaryBtnText}
      customFooter={customFooter}
      customHeader={customHeader}
      backDropClassName={styles.backdrop}
      modalPaperClassName={styles.desktopModalPaper}
      secondaryBtnClassName={secondaryBtnClassName}
      primaryBtnClassName={primaryBtnClassName}
      onClickCross={onClickCross}
      backDropClickClose={backDropClickClose}
      escapeKeyDownClose={escapeKeyDownClose}
      style={{ "--desktop-modal-width": desktopModalWidth }}
      {...modal_props}
    >
      <div
        ref={contentRef}
        className={classnames(styles.root, padded && styles.padded, className)}
        onScroll={onScroll}
        id={id}
      >
        {children}
      </div>
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={handleClose}
      header={title}
      customFooter={customFooter}
      customHeader={customHeader}
      primaryAction={{
        label: primaryBtnText,
        onClick: onPrimaryBtnClick,
        btnProps: primaryBtnProps,
        className: primaryBtnClassName,
      }}
      secondaryAction={
        showSecondaryBtn && {
          label: secondaryBtnText,
          onClick: onSecondaryBtnClick,
          btnProps: secondaryBtnProps,
          className: secondaryBtnClassName,
        }
      }
      backDropClassName={styles.backdrop}
      onClickCross={onClickCross}
      backDropClickClose={backDropClickClose}
      escapeKeyDownClose={escapeKeyDownClose}
      {...mobile_modal_props}
    >
      <div
        ref={contentRef}
        className={classnames(styles.root, padded && styles.padded, className)}
        onScroll={onScroll}
        id={id}
      >
        {children}
      </div>
    </MobileModal>
  );
};
