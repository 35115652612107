import constants from "constants/constants";

export const COMMUNICATIONS_ROUTE_KEYS = {
  COMPOSE_EMAIL: "COMPOSE_EMAIL",
};

export const COMMUNICATIONS_PATH_NAMES = {
  [COMMUNICATIONS_ROUTE_KEYS.COMPOSE_EMAIL]: ({ offeringId }) =>
    `ComposeEmail?template=${constants.exly_inv_template}&lisitng_uuid=${offeringId}`,
};
