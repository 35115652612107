import React from "react";
import { Route } from "react-router-dom";
import { CreateWhatsappPresetNotificationTemplate } from "../CreateWhatsappPresetNotificationTemplate";

export const CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE = {
  CREATE_PRESET_NOTIFICATION_TEMPLATE: "CREATE_PRESET_NOTIFICATION_TEMPLATE",
};

export const CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE_ROUTE_IDS = {
  [CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE.CREATE_PRESET_NOTIFICATION_TEMPLATE]:
    "/create-notification-template",
};

export const CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE_CUSTOM_ROUTES = [
  <Route
    key={
      CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE_ROUTE_IDS[
        CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE
          .CREATE_PRESET_NOTIFICATION_TEMPLATE
      ]
    }
    path={
      CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE_ROUTE_IDS[
        CREATE_WHATSAPP_NOTIFICATION_PRESET_TEMPLATE
          .CREATE_PRESET_NOTIFICATION_TEMPLATE
      ]
    }
    component={CreateWhatsappPresetNotificationTemplate}
  />,
];
