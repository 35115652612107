import { is_empty } from "features/Common/utils/common.utils";
import {
  WHATSAPP_TEMPLATES_FILTER_CONFIG_MAP,
  WHATSAPP_TEMPLATES_FILTER_KEYS,
} from "../modules/TemplateFilters/constants/TemplateFilters.constants";

export const getTemplatesListFilterConfig = ({ parentCommTitle }) => {
  const config = [
    WHATSAPP_TEMPLATES_FILTER_CONFIG_MAP[WHATSAPP_TEMPLATES_FILTER_KEYS.search],
  ];

  if (is_empty(parentCommTitle))
    config.push(
      WHATSAPP_TEMPLATES_FILTER_CONFIG_MAP[
        WHATSAPP_TEMPLATES_FILTER_KEYS.category_type
      ]
    );

  return config;
};
