import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { useCustomQuery } from "data/modules/api/hooks/useCustomQuery";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import { useEffect } from "react";
import { useForm } from "react-final-form";
import { whatsapp_broadcasts_apis } from "ui/pages/whatsappBroadcast/whatsappBroadCast.api";
import {
  TEMPLATE_APPROVAL_STATUS,
  TEMPLATE_FORM_FE_KEYS,
  TEMPLATE_FORM_KEYS,
} from "../constants/CreateWhatsappPresetNotificationTemplate.constants";
const { getEditorStateFromHtml } = RichTextEditorUtils;

export const useTemplate = ({ enabled = true } = {}) => {
  const form = useForm();
  const templateUuid = useFormFieldValue(
    TEMPLATE_FORM_FE_KEYS.selected_template_uuid
  );
  const {
    isFetching,
    isSuccess,
    data: templateData,
  } = useCustomQuery({
    url: whatsapp_broadcasts_apis.get_templates,
    queryKey: [
      whatsapp_broadcasts_apis.get_templates,
      { template_uuid: templateUuid },
    ],
    params: { template_uuid: templateUuid },
    enabled: !!templateUuid && enabled,
  });

  useEffect(() => {
    // initialize selected template data in form
    const handleSetSelectedTemplateData = () => {
      if (isSuccess && templateData) {
        const { template } = templateData;
        const {
          [TEMPLATE_FORM_KEYS.display_title]: templateName,
          [TEMPLATE_FORM_KEYS.body]: templateHTMLBody,
          [TEMPLATE_FORM_KEYS.footer]: templateFooter,
        } = template;
        const templateRTEBodyValue = templateHTMLBody
          ? getEditorStateFromHtml(
              `<p style="margin:0;min-height:18px">${templateHTMLBody}</p>` // TODO: remove it
            )
          : undefined;
        const { values: currentFormValues } = form.getState();
        const updatedData = {
          ...currentFormValues,
          ...template,
          [TEMPLATE_FORM_KEYS.approval_status]:
            TEMPLATE_APPROVAL_STATUS.UNDER_REVIEW,
          [TEMPLATE_FORM_KEYS.display_title]: templateName || undefined,
          [TEMPLATE_FORM_KEYS.description]: templateRTEBodyValue,
          [TEMPLATE_FORM_KEYS.footer]: templateFooter || undefined,
        };
        form.reset(updatedData);
      }
    };

    handleSetSelectedTemplateData();
  }, [isSuccess, templateData]);

  return { isFetching };
};
